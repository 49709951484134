form.form {
    width: 40%;
}

label.form-label {
    color: #CCCCCC;
    font-family: 'Roboto', sans-serif;
    margin: 3px 0;
}

button.login {
    font-family: 'Roboto', sans-serif;
    margin: 12px 0;
    border-radius: 4px;
    color: whitesmoke;
    border: none;
    background-color: #616161;
    font-weight: bold;
    padding: 5px 10px;
}

p.errmsg {
    color: #616161;
}

@media only screen and (max-width: 600px) {
    form.form {
        width: 75%;
    }
}