div.content-detail-header {
    text-align: center;
    color: #CCCCCC;
    font-weight: 800;
}

a.content-landar-main {
    text-decoration: none;
    color: #CCCCCC;
}

a.content-landar-main:hover {
    text-decoration: none;
    color: #FFFFFF;
    transition: color 0.75s;
}

div.content-detail-title.col {
    font-family: 'Lato', sans-serif;
    font-size: 40px;
    padding-bottom: 18px;
    font-size: 3rem;
}

div.content-detail-date.col {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 1.2rem;
    padding-bottom: 3rem;
    font-weight: 200;
    color: #FFFFFF;
}

div.track-items.col {
    margin: 1rme;
}

div#album-content.col-lg-5 {
    display: flex;
    justify-content: center;
}

div.content-card-detail.card {
    border-style: none;
}

img.card-img-top.content-card-detail {
    border-top-left-radius: unset;
    border-top-right-radius: unset;
}

div.content-card-detail.card-body {
    background-color: #212121;
    color: #FFFFFF;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 100;
}

div#album-content.col-lg-7 {
    margin: auto;
}

div#album-content.col-lg-6 {
    margin: auto;
}

a.album-songs-link {
    color: #FFFFFF;
    text-decoration:  none;
}

a.album-songs-link:hover {
    text-decoration: none;
    color: #CCCCCC;
    transition: color 0.75s;
}

li.album-songs {
    padding: 1rem 0;
    color: #FFFFFF;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 1rem;
    font-weight: 100;
    padding-left: .8rem;
} 

li.album-songs::marker {
    color: #FFFFFF;
}

div#song-card-detail.col-lg-5 {
    padding-right: 2rem;
}

span#content-title {
    font-style: normal;
}

p.paragraph.card-text {
    white-space: pre-line;
}

@media screen and (max-width: 991px) {
    object {
        /* height: 30rem;
        padding-top: 2rem; */
        display: none;
    }
    a.content-landar-main {
        font-size: 2rem;
    }
}

@media screen and (max-width: 768px) {
    object {
        height: 30rem;
        padding-top: 2rem;
    }
    a.content-landar-main {
        font-size: 2rem;
    }
}

@media screen and (max-width: 425px) {
    a.content-landar-main {
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 390px) {
    a.content-landar-main {
        font-size: 1.3rem;
    }
}
