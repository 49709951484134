.nav-tabs .nav-link {
    font-weight: 400;
    color: #CCCCCC;
    font-size: 1.2rem;
    font-family: 'Roboto Condensed', sans-serif;
}

button.manage-content.btn.btn-primary {
    color: #212121;
    background-color: #CCCCCC;
    border: none;
    font-family: 'Roboto Condensed', sans-serif;
    margin-top: 1rem;
}

button.manage-create-content.btn.btn-primary {
    color: #212121;
    background-color: #FFFFFF;
    border: none;
    font-family: 'Roboto Condensed', sans-serif;
    margin-top: 1rem;
}

div.modal-title.h4 {
    font-family: 'Roboto Condensed', sans-serif;
}

label.manage-content.form-label {
    color: #212121;
}

div.modal-content {
    background-color: #CCCCCC;
}

a.manage-link {
    color: #ee1c27fb;
}

th {
    font-family: 'Roboto Condensed', sans-serif;
}

td {
    font-family: 'Roboto Condensed', sans-serif;
}