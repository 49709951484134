/* file controls the styling and hover functionality of the content images on the albums and songs tab */

.artwork {
    opacity: 1;
    display: block;
    transition: .5s ease;
    backface-visibility: hidden;
    width: 100%;
    height: auto;
    padding-bottom: 2rem;
}

.artworkBox:hover img {
    opacity: 0.6;
}

.artworkBox:hover {
    opacity: 1;
}

.artworkBox {
    display: inline-block;
    overflow: hidden;
}
.artworkBox .artwork {
    display: block;
    transition: .3s ease-in-out;
}

.artworkBox:hover .artwork {
    cursor: pointer;
    transform: scale(1.02);
} 