div.footer-container.container-fluid {
    padding: 3rem;
    font-family: 'Roboto Condensed', sans-serif;
}

div.footer-row.row {
    color: #ee1c27fb;
}

.child-left {
    display: inline-block;
    padding-left: 1rem;
    vertical-align: middle;
    font-size: 1.2rem;
}

.child-right {
    display: inline-block;
    padding-left: 1rem;
    vertical-align: middle;
}

button.footer-nav {
    all: unset;
    text-decoration: none;
    color: #ee1c27fb;
}

button.footer-nav:hover {
    text-decoration: none;
    color: #FFFFFF;
    transition: color 0.75s;
    cursor: pointer;
}

a.footer-nav {
    text-decoration: none;
    color: #ee1c27fb;
}

a.footer-nav:hover {
    text-decoration: none;
    color: #FFFFFF;
    transition: color 0.75s;
}

a.social-link.footer {
    color: #ee1c27fb;
    font-size: 1.2rem;
} 

a.social-link.footer:hover {
    text-decoration: none;
    color: #FFFFFF;
    transition: color 0.75s;
}

div.footer-right.col {
    text-align: right;
}

div.child-left.login {
    display: none;
}

/* On screens that are 575px or less */
@media screen and (max-width: 575px) {
    div.footer-right.col {
        text-align: left;
    }
    .child-left {
        padding-bottom: 1rem;
    }
}