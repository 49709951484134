div.hero.container-fluid {
    background: url(../assets/Keyboard.jpg);
    background-position: center, bottom left;
    background-size: cover, cover;
    height: fit-content;
    color: #fafafc;
    padding: 5rem 3rem 6rem;
}

div.about-col.col-xl-12 {
    display: flex;  
    justify-content: center;
}

.about-card {
    width: 50%;
    height: auto;
}

div.about-card.card {
    border-style: none;
}

.about-artwork {
    border: 1.3rem solid #212121;;
    border-top-left-radius: unset;
    border-top-right-radius: unset;
}

.about-description {
    display: inline-block;
    border: 5px solid #212121;;
    text-align: left;
    background-color: #212121;;
    color: #FFFFFF;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 100;
    font-size: 20px;
    padding: 30px 25px;
}

.logo {
    width: 5%;
    padding-top: 5rem;
}

/* On screens that are 992px or less */
@media screen and (max-width: 992px) {
    .about-card {
        width: 80%;
        height: auto;
    }
} 

@media screen and (max-width: 768px) {
    .logo {
        width: 8%;
    }
} 

@media screen and (max-width: 425px) {
    .about-card {
        width: 90%;
        height: auto;
    }
    .logo {
        width: 15%;
    }
} 

@media screen and (max-width: 375px) {
    .about-card {
        width: 100%;
        height: auto;
    }
    .logo {
        width: 15%;
    }
} 