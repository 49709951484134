.just-released-title {
    text-align: center;
}

div.just-released-row.row {
    justify-content: center;
}

div.just-released-col.col-lg-12 {
    max-width: 55%;
}

.just-released-artwork {
    width: 100%;
    height: auto;
    padding-bottom: 1rem;
}

a.social-link.just-released {
    text-decoration: none;
    font-size: 30px;
    font-family: 'Roboto', sans-serif;
    color: #CCCCCC;
}

a.social-link.just-released:hover {
    text-decoration: none;
    color: #FFFFFF;
    transition: color 0.75s;
}

span {
    padding-left: 1rem;
    vertical-align: middle;
    font-style: italic;
}

@media screen and (max-width: 1024px) {
    div.just-released-col.col-lg-12 {
        max-width: 85%;
    }
}

/* On screens that are 768px or less */
@media screen and (max-width: 768px) {
    a.social-link.just-released {
        font-size: 1.4rem;
    }
    div.just-released-col.col-lg-12 {
        max-width: 55%;
    }
    div.just-released-col.col-lg-12 {
        max-width: 85%;
    }
}

/* On screens that are 425px or less */
@media screen and (max-width: 425px) {
    a.social-link.just-released {
        font-size: 1.2rem;
    }
    div.just-released-col.col {
        max-width: 95%;
    }
    div.just-released-col.col-lg-12 {
        max-width: 95%;
    }
}

/* On screens that are 350px or less */
@media screen and (max-width: 350px) {
    a.social-link.just-released {
        font-size: 1rem;
    }
    div.just-released-col.col-lg-12 {
        max-width: 100%;
    }
}